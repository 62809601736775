<template>
  <div class="view view--light">
    <v-card elevation="1" class="mt-6 row--table-container" v-if="customerAircraft">
      <v-row>
        <v-col :cols="12" class="py-0">
          <TableOperatingHours :items="data" :height="tableHeight" class="table__equipment-list" />
        </v-col>
      </v-row>
    </v-card>

    <v-row class="mt-4" v-else>
      <v-col :cols="12">
        <AlertNoAircraft />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { get } from 'lodash';
import { mapGetters } from 'vuex';
import { MODULE_NAME as CUSTOMER_AIRCRAFTS_MODULE } from '@/store/modules/customerAircrafts';
import AlertNoAircraft from '@/components/Alert/AlertNoAircraft';
import TableOperatingHours from '@/components/Table/TableOperatingHours';
import tableMixin from '@/shared/mixins/table';

export default {
  name: 'OparatingHoursIndex',

  mixins: [tableMixin],

  components: {
    AlertNoAircraft,
    TableOperatingHours
  },

  computed: {
    ...mapGetters({
      customerAircraft: `${CUSTOMER_AIRCRAFTS_MODULE}/selected`
    }),

    customerAircraftId() {
      return get(this.customerAircraft, 'id');
    },

    salesname() {
      return get(this.customerAircraft, 'aircraft.easa_types.salesname');
    },

    variant() {
      return get(this.customerAircraft, 'aircraft.easa_types.variant');
    }
  },

  data() {
    return {
      data: [
        {
          benennung: 'Propeller KS-1g',
          werkNr: '514',
          betriebszeitTeil: '300 Stunden',
          betriebszeitEinbauTeil: '0 Stunden',
          betriebszeitEinbauLfz: '0 Stunden DEI',
          ausbau: '300 Stunden DEI'
        },
        {
          benennung: 'Motor Solo 2625 01',
          werkNr: '447',
          betriebszeitTeil: '400 Stunden',
          betriebszeitEinbauTeil: '0 Stunden',
          betriebszeitEinbauLfz: '0 Stunden DEI',
          ausbau: '400 Stunden DEI'
        },
        {
          benennung: 'Zahnriemen',
          werkNr: 'ohne',
          betriebszeitTeil: '50 Stunden',
          betriebszeitEinbauTeil: '0 Stunden',
          betriebszeitEinbauLfz: '49 Stunden DEI',
          ausbau: '99 Stunden DEI'
        },
        {
          benennung: '25 Std. Kontrolle',
          werkNr: 'ohne',
          betriebszeitTeil: '25 Stunden',
          betriebszeitEinbauTeil: '0 Stunden',
          betriebszeitEinbauLfz: '71 Stunden DEI',
          ausbau: '96 Stunden DEI'
        },
        {
          benennung: 'Dichtung des Drainers',
          werkNr: 'ohne',
          betriebszeitTeil: '6 Jahre',
          betriebszeitEinbauTeil: 'neu',
          betriebszeitEinbauLfz: 'Jan 14',
          ausbau: 'Jan 20'
        },
        {
          benennung: 'Kühlwasserschläuche',
          werkNr: 'ohne',
          betriebszeitTeil: '6 Jahre',
          betriebszeitEinbauTeil: 'neu',
          betriebszeitEinbauLfz: 'Jan 14',
          ausbau: 'Jan 20'
        },
        {
          benennung: 'Schwerpunktkupplung',
          werkNr: '62921',
          betriebszeitTeil: '2000 Ldg',
          betriebszeitEinbauTeil: '0 Ldg',
          betriebszeitEinbauLfz: '0 Ldg',
          ausbau: '2000 Ldg'
        }
      ],
      tableColumns: [
        {
          text: 'Date',
          value: 'date'
        },
        {
          text: 'Title',
          value: 'title'
        },
        {
          text: 'Revision',
          value: 'revision'
        },

        {
          text: 'Tags',
          value: 'tags'
        }
      ],
      tableHeight: ''
    };
  },

  mounted() {
    this.init();
  },

  methods: {
    init() {}
  }
};
</script>
